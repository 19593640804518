<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Categoria - Editar</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="EditCategory">
              <b-row>
                
                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Nombre:">
                    <b-form-input
                      type="text"
                      ref="name"
                      v-model="category.name"
                      placeholder="Ingrese su nombre"
                    ></b-form-input>
                    <small v-if="errors.name" class="form-text text-danger"
                      >Ingrese un nombre</small
                    >
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>

        

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Descripción :" required>
                    <b-form-input
                      type="text"
                      ref="description"
                      v-model="category.description"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>


                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Estado :" label-for="input-1">
                    <select
                      ref="state"
                      v-model="category.state"
                      class="form-control"
                    >
                      <option value="1">Activo</option>
                      <option value="0">Inactivo</option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>


                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-button
                    type="submit"
                    class="form-control bg-primary text-white"
                    variant="primary"
                    >GUARDAR</b-button
                  >
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";

export default {
  name: "CategoryEdit",
  props: ["id_category"],
  data() {
    return {
      category: {
        id_category: "",
        name: "",
        description: "",
        state: 1,
      },

      ubigens: [],
      //errors
      errors: {
        name: false,
        name: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewCategory();
  },
  methods: {
    ViewCategory,
    EditCategory,
    Validate,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};


//ver usuario
function ViewCategory() {
  let id_category = je.decrypt(this.id_category);
  let me = this;
  let url = this.url_base + "category/view/" + id_category;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: "Categoria",
      role: 3,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.category.id_category = response.data.result.id_category;
        me.category.name = response.data.result.name;
        me.category.description = response.data.result.description;
        me.category.state = response.data.result.state;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

//editar usuario
function EditCategory() {
  // validacion de campos obligatorios
  this.Validate();
  if (this.validate) {
    return false;
  }

  let me = this;
  let url = this.url_base + "category/edit";
  let data = {
    id_category: this.category.id_category,
    name: this.category.name,
    description: this.category.description,
    state: this.category.state,
  };

  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      token: this.token,
      module: "Categoria",
      role: 3,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire("Categoria", "Se ha modificado la categoria", "success");
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

//validacion de formulario
function Validate() {
  this.errors.id_category = this.category.id_category.length == 0 ? true : false;
  this.errors.name = this.category.name.length == 0 ? true : false;
  this.errors.state = this.category.state.length == 0 ? true : false;

  this.validate = this.errors.id_category == true ? true : false;
  this.validate = this.errors.name == true ? true : false;
  this.validate = this.errors.state == true ? true : false;

}
</script>
